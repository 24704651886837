import { Injectable } from '@angular/core';
import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FingerPrintService {
  private visitorId: string;

  /**
   * @constructor
   */
  constructor(private cookieService: CookieService) {
    this.fingerPrintLoad();
  }

  /**
   * @description getVisitorId
   * @return string
   */
  public getVisitorId(): string {
    return this.visitorId || this.cookieService.get('client_id');
  }

  /**
   * @description fingerPrintLoad
   * @return Promise<void>
   */
  private fingerPrintLoad(): void {
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        this.setVisitorId(result);
      });
  }

  /**
   * @description setVisitorId
   * @param result
   */
  private setVisitorId(result: GetResult): void {
    this.visitorId = result.visitorId;
    const {hostname} = window.location;
    this.cookieService.set('client_id', this.visitorId, {
      domain: new RegExp(environment.SHARED_DOMAIN_NAME).test(hostname) ? environment.SHARED_DOMAIN_NAME : hostname,
      path: '/'
    });
  }
}
